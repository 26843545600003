<template>
  <div class="songinfo-bar" v-show="showSongInfoBar">正在演唱：{{ aiFaceSong?.name }}</div>
</template>

<script>
import { ref, watch, toRefs } from 'vue';

let timer = null;
export default {
  name: 'SongInfoBar',
  props: {
    aiFaceSong: Object,
  },
  setup(props) {
    const { aiFaceSong } = toRefs(props)
    const showSongInfoBar = ref(false);

    watch(aiFaceSong, () => {
      if (timer) {
        clearTimeout(timer);
      }
      showSongInfoBar.value = true;
      timer = setTimeout(() => {
        showSongInfoBar.value = false;
      }, 6000);
    }, {
      immediate: true,
      deep: true,
    });

    return {
      showSongInfoBar,
    };
  },
}
</script>

<style lang="stylus" scoped>
.songinfo-bar
  width: 800px;
  height: 100px;
  border-radius: 14px;
  padding 0 40px
  background: rgba(30, 31, 33, 0.7);
  display flex
  align-items center
  justify-content center
  position fixed
  z-index 10
  top 43px
  left 50%
  margin-left -400px
  color #fff
  font-size 30px
</style>

