<template>
  <div
    class="climax"
    :class="isShowGuide && 'show-guide'"
    v-if="mvIsHide"
    v-log="{
      event_type: 'show',
      event_name: 30148,
      event_data: {
        str1: '副歌页',
        str2: '副歌页',
        str3: '进入副歌页',
        str4: 'show',
        str7: userType,
      }
    }"
  >
    <video-player
      :class="isScale && 'scale'"
      from="climax"
      ref="videoPlayerRef"
      :autoplay="autoplay"
      :startPosition="startPosition"
      :src="climaxSong.source_url"
      :token="''"
      :poster="climaxSong.poster"
      @timeupdate="handleTimeUpdate"
      @ended="handleVideoEnded"
      @canplay="handleCanPlay"
    />
    <div class="climax-controls">
      <div v-if="isShowGuide"  class="climax-controls-mask">
        <div class="center">
          <h3>快唱高潮</h3>
          <p>爆款热歌开唱不用等前奏  可以直接唱副歌~</p>
          <div class="play-btn" @click="handleClickPlayBtn">开始快唱(
            <van-count-down
              :time="time"
              format="S"
              @change="handleCountDownChange"
            />
            <span>s</span>
            )
          </div>
        </div>
      </div>
      <div class="climax-controls-back" @click="handleClickBack"></div>
      <div class="play-controls">
        <div>
          <button
            @click="playPrevious"
             v-log="{
              event_type: 'click',
              event_name: 30155,
              event_data: {
                str1: '副歌页',
                str2: '上一首',
                str3: '上一首点击',
                str4: 'click',
                str7: userType,
              }
            }"
          >上一首</button>
          </div>
          <div>
          <button
            @click="playNext"
            class="playNext"
            v-log="{
              event_type: 'click',
              event_name: 30158,
              event_data: {
                str1: '副歌页',
                str2: '下一首',
                str3: '下一首点击',
                str4: 'click',
                str7: userType,
              }
            }"
          >下一首</button>
          <div class="tip">点击可切换歌曲</div>
        </div>
        <div>
          <button
            class="play"
            @click="handleSingFullSong"
            v-log="{
              event_type: 'click',
              event_name: 30160,
              event_data: {
                str1: '副歌页',
                str2: '唱整首',
                str3: '唱整首点击',
                str4: 'click',
                str5: isLogin ? '已登录' : '未登录',
                str7: userType,
              }
            }"
          >唱整首</button>
          <div class="tip">
            <p>喜欢的歌</p>
            <p>可加入已点继续欢唱</p>
          </div>
        </div>
      </div>
      <transition name="fade">
        <SongInfoBar
          v-if="isShowSongInfo"
          :key="climaxNextSong.songid"
          :currentSong="climaxSong"
          :nextSong="climaxNextSong"
        />
      </transition>
      <!-- <div
        v-if="isShowTip"
        class="climax-tip"
        @click="handleClickTip"
        v-log="{
          event_type: 'click',
          event_name: 30167,
          event_data: {
            str1: '副歌页',
            str2: '试唱提示',
            str3: '试唱提示点击',
            str4: 'click',
            str5: isLogin ? '已登录' : '未登录',
            str7: userType,
          }
        }"
      >
        <span class="close" @click.stop="isShowTip = false"></span>
      </div> -->
    </div>
    <van-popup
      :show="showSidebar"
      :position=" clientWidth == 1200?'bottom':'left'"
      :style="{  height: clientWidth == 1200?'56vh':'100vh', background: 'none' }"
      :overlay="false"
      @opened="isScale = true"
      @close="isScale = false"
    >
      <div class="drawer left-side">
        <div v-if="clientWidth == 1200">
          <img src="https://qncweb.ktvsky.com/20240509/vadd/86e65f7aa632d5a280907401b58fc178.png" class="right-back"  />
          <img src="https://qncweb.ktvsky.com/20240509/vadd/559d16dd9529173522376918f1dfe77a.png" class="right-close"  />
        </div>
        <div class="close" @click="showSidebar = false"></div>
        <h3>
          <img :src="sidebarTitle" v-if="isLogin" class="loginTit"/>
          <img :src="sidebarTitle" v-else class="exitTit"/>
        </h3>
        <template v-if="isLogin">
          <div class="vip-img">
            <img src="https://qncweb.ktvsky.com/20240412/other/f2679b62ff40fb55c641c0deeed8e984.png" alt="">
          </div>
          <UserVip from="climax" :fr="userVipFr" />
        </template>
        <template v-else>
          <div class="banner">
            <img src="https://qncweb.ktvsky.com/20240412/other/a1f468362c604fe07b9c198c32a931a9.png" />
          </div>
          <div class="login-qr"><img :src="qrCodeURL" alt=""></div>
        </template>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { onBeforeMount, ref, computed, watch, watchEffect, nextTick, onMounted } from 'vue';
import { useStore } from 'vuex';
import { useRouter,useRoute,  onBeforeRouteLeave } from 'vue-router';
import _ from 'lodash';
import VideoPlayer from '@/components/video-player/index.vue';
import SongInfoBar from '@/components/mv/components/songinfo-bar/index.vue';
import { CountDown, Popup } from 'vant';
import eventBus from '@/utils/event-bus';
import store2 from 'store2'
import Toast from '@/utils/toast'
import useOrder from '@/composables/useOrder'
import useQRCode from '@/composables/useQRCode'
import { getCarplayInfo } from '@/service/carplay-info'
import { vipLogFrom } from '@/constants/index'
import UserVip from '@/components/vip/index.vue'
import { getClimaxTip, getClimaxTime, setClimaxTime } from '@/utils/historyCache'
import useVip from '@/composables/useVip'
import { sendLog } from '@/directives/v-log/log'
import { useShareBrowserSize } from '@/composables/sharedComposable'
import useLoading from '@/composables/useLoading'
import useActivity from '@/composables/useActivity'

export default {
  name: 'ClimaxSong',
  components: {
    VideoPlayer,
    SongInfoBar,
    VanCountDown: CountDown,
    UserVip,
    VanPopup: Popup,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const store = useStore();
    const { addSong, orderSong, normalOrderSong } = useOrder();
    const { getQRCodeURL } = useQRCode();
    const { showVipQrcode, vipQrcodeInstance } = useVip()
    const { showLoading, hideLoading } = useLoading()
    // Refs
    const videoPlayerRef = ref(null);
    const currentIndex = ref(0);
    const isShowGuide = ref(false);
    const autoplay = ref(false);
    const time = ref(6 * 1000);
    const isShowSongInfo = ref(false);
    const showSidebar = ref(false)
    const isScale = ref(false)
    const qrCodeURL = ref('https://qncweb.ktvsky.com/20211130/vadd/82b806b8bf560e49df69162835879c65.png');
    const sidebarTitle = ref('https://qncweb.ktvsky.com/20240412/other/8c455aca6549ee73ed316bc3a2f3f8a4.png')
    // const isShowTip = ref(false)
    // const timer = ref(null);
    const userVipFr = ref(null)
    // const songInfoTimer = ref(null)
    let startTime = ref(0);
    let elapsedTime = ref(0);
    let isPopupShown = ref(false);
    // let timer = ref(null)
    let kcInstance = ref(null)
    // Computed
    const isLogin = computed(() => !!store.state.userInfo.unionid);
    const isVip = computed(() => !!store.state.vipInfo.end_time);
    const userType = computed(() => store.state.userInfo.userType);
    const end_time = computed(() => store.state.vipInfo.end_time)
    const mvIsHide = computed(() => store.state.mvIsHide);
    const initControlPlay = computed(() => store.state.videoInitAutoPlay)
    const orderedListNum = computed(() => store.state.orderedList.length);
    const climaxList = computed(() => _.shuffle(store.state.climax.climaxList));
    const climaxSong = computed(() => store.state.climax.climaxSong);
    const climaxNextSong = computed(() => {
      console.log(nextSong ? nextSong : climaxList.value[0])
      const nextSong = climaxList.value[currentIndex.value + 1];
      return nextSong ? nextSong : climaxList.value[0];
    });
    const { showActivityKuaiChang } = useActivity()

    const startPosition = computed(() => timeToSeconds(_.get(store.state.climax, 'climaxSong.start_time', '')));

    const { clientWidth } = useShareBrowserSize()
    // Methods
    const playNext = () => {
      showLoading()
      // clearTimeout(songInfoTimer.value);
      currentIndex.value = (currentIndex.value + 1) % climaxList.value.length;
      store.dispatch('climax/setCurrentClimaxSong', climaxList.value[currentIndex.value]);
    };

    const playPrevious = () => {
      // clearTimeout(songInfoTimer.value);
      showLoading()
      currentIndex.value = (currentIndex.value - 1 + climaxList.value.length) % climaxList.value.length;
      store.dispatch('climax/setCurrentClimaxSong', climaxList.value[currentIndex.value]);
    };

    const timeToSeconds = (timeStr) => {
      if (!timeStr) return 0;
      const [hours, minutes, seconds] = timeStr.split(':').map(Number);
      return hours * 3600 + minutes * 60 + seconds;
    };

    const handleTimeUpdate = (t) => {
      if (timeToSeconds(climaxSong.value.end_time) < t) {
        playNext();
      }
    };

    const handleVideoEnded = () => {
      playNext();
    };

    const handleClickBack = () => {
      router.go(-1);
    };

    const handleCountDownChange = (time) => {
      if (time.seconds === 0) {
        isShowGuide.value = false;
        autoplay.value = true;
        videoPlayerRef.value.handleControlVideoPlay()
      }
    };

    const handleClickPlayBtn = () => {
      isShowGuide.value = false;
      autoplay.value = true;
      videoPlayerRef.value.handleControlVideoPlay()
    };

    const showSongInfo = () => {
      isShowSongInfo.value = true;
      // songInfoTimer.value = setTimeout(() => {
      //   isShowSongInfo.value = false;
      // }, 10000); // 3秒后隐藏
    };

    const showQRCodeDialog = async (log) => {
      const { data } = await getCarplayInfo();

      if (data.pay_qr) {
        const loginUrl = `${data.pay_qr}&log=${vipLogFrom.get(log)}&ut=${userType.value}`;
        const qrCodeData = await getQRCodeURL(loginUrl);
        if (qrCodeData) {
          qrCodeURL.value = qrCodeData;
        }
        return;
      }
      Toast('未获取到登录二维码');
    };

    const addToPlaylistAndRedirect = async () => {
      try {
        if (initControlPlay.value) {
          await Promise.all([
            addSong(climaxSong.value),
            orderSong(climaxSong.value, orderedListNum.value - 1)
          ]);
        } else {
          await store.commit('UNSHIFT_SONG_ORDERED_LIST', climaxSong.value);
          normalOrderSong(climaxSong.value, {
            position: 'recovery',
            isPushOrdered: false,
            enabledMvShow: true,
            useFirstSongAutoPlay: true,
          })
        }
        await store.commit('UPDATE_MV_ISHIDE', false)

        Toast({
          message: '已进入欢唱页，开始唱整首吧',
          duration: 5000
        });

        setTimeout(() => {
          eventBus.emit('video-control-play');
        }, 2000);
      } catch (error) {
        // 处理错误
        console.error('添加到播放列表并重定向时出错：', error);
      }
    };

    const handleClickTip = () => {
      showSidebar.value = true
      sidebarTitle.value = 'https://qncweb.ktvsky.com/20240415/other/c684b9deaa9ac2e69a1a7943875533ce.png'
      if (!isLogin.value) {
        showQRCodeDialog('副歌页试唱提示')
      } else {
        userVipFr.value = vipLogFrom.get('副歌页试唱提示-车机')
      }
    }

    const handleSingFullSong = () => {
      store.dispatch('vipAddSong/resetSong')
      if (!climaxSong.value.is_vip || isVip.value) {
        addToPlaylistAndRedirect();
        return;
      }
      showSidebar.value = true
      sidebarTitle.value = 'https://qncweb.ktvsky.com/20240412/other/8c455aca6549ee73ed316bc3a2f3f8a4.png'
      if (!isLogin.value) {
        showQRCodeDialog('副歌页唱整首');
      } else {
        userVipFr.value = vipLogFrom.get('副歌页唱整首-车机')
      }
    };

    const handleShowVipQrcode = async () => {
      await nextTick()
      showSidebar.value = false
      eventBus.emit('handle-video-pause');
      const log = isLogin.value ? '副歌页副歌VIP弹窗-车机' : '副歌页副歌VIP弹窗'
      showVipQrcode({
        fromType: 'climax',
        log,
        isLogin: isLogin.value,
        fr: vipLogFrom.get(log),
        closeCallback: () => {
          eventBus.emit('handle-close-popUp')
          router.back()
          if (vipQrcodeInstance.value) {
            console.log('vipQrcodeInstance')
            vipQrcodeInstance.value.hide()
          }
          sendLog({
            event_type: '10000~50000',
            event_name: 30169,
            event_data: {
              str1: '副歌页',
              str2: '副歌VIP弹窗',
              str3: '关闭按钮点击',
              str4: 'click',
              str5: isLogin.value ? '已登录' : '未登录',
              str7: userType.value,
            },
          })
        }
      })
      sendLog({
        event_type: '10000~50000',
        event_name: 30168,
        event_data: {
          str1: '副歌页',
          str2: '副歌VIP弹窗',
          str3: '副歌VIP弹窗展示',
          str4: 'show',
          str5: isLogin.value ? '已登录' : '未登录',
          str7: userType.value,
        }
      });
      setClimaxTime({ isPopupShown: true })
      // clearTimeout(timer.value);
      isPopupShown.value = true
    }

    // const startTimer = () => {
    //   timer.value = setTimeout(() => {
    //     handleShowVipQrcode()
    //   }, (5 * 60 * 1000) - elapsedTime.value); // 减去已经计时的时间
    // };
    onMounted(() => {
      kcInstance.value = showActivityKuaiChang({
        currentSong: climaxSong,
        nextSong: climaxNextSong
      })
    });
    onBeforeMount(async () => {
      eventBus.emit('handle-video-pause');

      await nextTick();

      if (climaxList.value.length > 0) {
        store.dispatch('climax/setCurrentClimaxSong', climaxList.value[currentIndex.value]);
      }

      // isShowTip.value = (!isLogin.value || !isVip.value) && getClimaxTip();

      if (isVip.value) {
        autoplay.value = true
        return
      }
      const storageTime = getClimaxTime();

      if (storageTime) {
        startTime.value = storageTime.startTime;
        elapsedTime.value = storageTime.elapsedTime;

        if (startTime.value) {
          elapsedTime.value = Date.now() - startTime.value;
        } else {
          startTime.value = Date.now();
          elapsedTime.value = 0;
        }

        // startTimer();

        const hasShownGuide = store2.get('hasShownGuide');
        autoplay.value = !!hasShownGuide;

        if (hasShownGuide) {
          isShowGuide.value = false;
          showSongInfo();
        } else {
          isShowGuide.value = true;
          store2.set('hasShownGuide', 'true');
        }
      } else {
        autoplay.value = false;
        isPopupShown.value = true;
        handleShowVipQrcode()
      }
    });


    const handleCanPlay = () => {
      if (kcInstance.value) {
        kcInstance.value.hide()
        kcInstance.value = null
      }
      hideLoading()
      // 这三首歌原版唱的音轨反了，单独处理下
      const reverseData = [7402766, 7572506, 7542909]
      videoPlayerRef.value.handleSwitchAudioTrack({
        id: reverseData.includes(climaxSong.value.songid) ? 1 : 0
      })
    }

    onBeforeRouteLeave((to, from, next) => {
      if (!isVip.value) {
        setClimaxTime({
          startTime: startTime.value,
          elapsedTime: elapsedTime.value,
          isPopupShown: isPopupShown.value
        })
      }
      // clearTimeout(timer.value);
      next()
    })

    watch(
      () => climaxSong.value,
      (newSong) => {
        if (newSong) {
          showSongInfo();
        }
      });
    
    // watch(isVip, (newVal) => {
    //   if (newVal) {
    //     clearTimeout(timer.value);
    //   }
    // }, { immediate: true })

    watch(isPopupShown, (newVal) => {
      if (newVal) {
        eventBus.emit('video-control-pause');
      }
    }, {
      immediate: true
    })

    watch(end_time, (newTime, oldTime) => {
      if (newTime === oldTime) {
          return;
      }

      if (newTime) {
        if (showSidebar.value) {
          addToPlaylistAndRedirect();
        }
        showSidebar.value = false;
        isPopupShown.value = false;
        autoplay.value = true;
        // clearTimeout(timer.value);
        videoPlayerRef.value.handleControlVideoPlay();
        if (isLogin.value) {
          Toast('VIP开通成功');
        }
      } else {
        handleShowVipQrcode();
        isPopupShown.value = true;
        eventBus.emit('video-control-pause')
      }
    });

    // watchEffect(() => {
    //   if (isShowTip.value && !isShowGuide.value) {
    //     // const timer = setTimeout(() => {
    //     //   isShowTip.value = false;
    //     //   clearTimeout(timer);
    //     // }, 10 * 1000);
    //   }
    // });

    return {
      videoPlayerRef,
      climaxList,
      climaxSong,
      startPosition,
      playNext,
      playPrevious,
      handleTimeUpdate,
      handleVideoEnded,
      climaxNextSong,
      handleClickBack,
      isShowGuide,
      time,
      handleCountDownChange,
      autoplay,
      handleClickPlayBtn,
      isShowSongInfo,
      handleSingFullSong,
      showQRCodeDialog,
      addToPlaylistAndRedirect,
      qrCodeURL,
      showSidebar,
      isScale,
      isVip,
      isLogin,
      // isShowTip,
      handleClickTip,
      sidebarTitle,
      mvIsHide,
      userVipFr,
      userType,
      handleCanPlay,
      clientWidth
    };
  },
};
</script>

<style lang="stylus" scoped>
.climax {
  background #000
  position relative
  z-index 10
  width 100vw
  height 100vh
  .climax-controls{
    &-back {
      width 130px
      height 130px
      background-color rgba(30, 31, 33, 0.7)
      background-image url('https://qncweb.ktvsky.com/20231207/vadd/72ff0b114ee2cb3153ce901af19bc813.png')
      background-repeat no-repeat
      background-size 40px 40px
      background-position center
      position fixed
      top 40px
      left 40px
      z-index 5
      border-radius: 10px;
    }
    &-mask {
      position fixed
      top 0
      left 0
      z-index 6
      background rgba(0, 0, 0, 0.9)
      width 100vw
      height 100vh
      display flex
      justify-content center
      align-items center
      .center {
        text-align center
        @media screen and (max-width: 1200px){
          margin-bottom: 730px
        } 
        h3 {
          font-size 68px;
          font-weight 700
          background-image: linear-gradient(230.62deg, #A000FB 7.51%, #F152FF 87.32%);
          -webkit-background-clip: text;
          background-clip: text;
          color: transparent;
        }
        p {
          font-size 32px;
          background-image: linear-gradient(229.44deg, #E3C1FF -13.49%, #FFE5C3 102.17%);
          -webkit-background-clip: text;
          background-clip: text;
          color: transparent;
        }
        .play-btn {
          width 300px
          height 100px
          display flex
          justify-content center
          align-items center
          background: linear-gradient(90deg, #D3F7EC 0%, #F0C4E5 100%);
          border-radius 50px
          font-size 32px
          color rgba(0, 47, 32, 1)
          margin 60px auto 0
          ::v-deep .van-count-down {
            font-size 32px
            color rgba(0, 47, 32, 1)
          }
          span {
            font-size 24px
          }
        }
      }
    }
  }
  .play-controls {
    display: flex;
    flex-direction column;
    justify-content: space-around;
    margin-top: 20px;
    position: fixed;
    z-index: 10;
    left: 40px;
    top: 50%;
    transform: translateY(-50%);
    background: rgba(30, 31, 33, 0.7);
    width: 130px;
    height: 438px;
    border-radius: 10px;
    padding 30px 0
    @media screen and (max-width: 1200px){
      width: 800px;
      display: flex;
      flex-direction: row;
      background: none;
      margin-top: 500px;
      margin-left: 160px;
    } 
    & > div {
      position relative
      .tip {
        display none
        position absolute
        top 50%
        transform translateY(-50%)
        left 180px
        font-size 22px
        color rgba(255, 255, 255, 0.5)
        font-size 28px
        color rgba(255, 255, 255, 1)
        padding 16px 24px
        white-space nowrap
        background: linear-gradient(90deg, rgba(211, 247, 236, 0.2) 0%, rgba(240, 196, 229, 0.2) 100%);
        border-radius 12px
        line-height 35px
      }
    }
  }

  button {
    @media screen and (max-width: 1200px){
      width: 220px
      height: 96px
      border-radius: 80px
      background: rgba(255, 255, 255, 0.1) no-repeat
      padding-top: 0px
      padding-left: 60px
      background-position: 25px 6px;
    } 
    font-size 22px
    color rgba(255, 255, 255, 0.5)
    width 100%
    height 120px
    background-size 80px auto
    background-position top center
    background-repeat no-repeat
    padding-top 50px
    &:first-child {
      background-image url(https://qncweb.ktvsky.com/20240412/other/10fd22d88bc1a6deee1d2491fd287c38.png)
    }
    &.playNext {
      background-image url(https://qncweb.ktvsky.com/20240412/other/fae082009d1c750458b7b9be0bb1a5d5.png)
      //margin 10px 0
    }
    &.play {
      background-image url(https://qncweb.ktvsky.com/20240412/other/3421bdb6f84665bb873338531a108568.png)
    }
  }
  .show-guide {
    .play-controls {
      background none!important
      & > div {
        background: rgba(218, 246, 236, 0.2)
        border-radius 10px
        &:last-child {
          margin-top 10px
        }
      }
      .tip {
        display block
      }
    }
  }
  .left-side {
    width 800px
    height 100vh
    z-index 10
    background url('https://qncweb.ktvsky.com/20240412/other/313faf31737a5192fabda9f6b38e03ef.png') no-repeat
    background-size 100% 100%
    overflow hidden
    @media screen and (max-width: 1200px){
      width 100vw
      height 56vh
      background url('https://qncweb.ktvsky.com/20240509/vadd/269c6c0929ab658f87905197098ba6e8.png') no-repeat
      background-size 100% 100%
      background-size: cover;
      position relative
      .right-back{
        position absolute
        right 170px
        top 130px
      }
      .right-close{
        position absolute
        right 30px
        top 30px
      }
    }
    & > h3 {
      margin-top 142px
      margin-left 60px
    }
    .vip-img {
      margin 40px auto 0
      width 675px
      padding-top 30px
      border-top dashed 2px rgba(255, 255, 255, 0.1)
      @media screen and (max-width: 1200px){
        padding-top 110px
      }
    }
    .banner {
      width 660px
      margin 98px auto 0
      @media screen and (max-width: 1200px){
        margin-top 140px
      }
    }
    .login-qr {
      width 240px
      height 240px
      margin 0 auto
      border-radius 10px
      background-color #fff
      margin-top 100px
      display flex
      justify-content center
      align-items center
      img {
        width 224px
        height 224px
      }
    }
    .close {
      position absolute
      width 70px
      height 70px
      top 50px
      right 30px
    }
    ::v-deep .packages {
      position relative
      &::after {
        width 100px
        height 260px
        content: '';
        position: absolute;
        left 700px
        top 60px
        background: linear-gradient(90deg, rgba(22, 9, 65, 0) 0%, #160941 100%);
        @media screen and (max-width: 1200px){
          content none;
          background none;
        }
      }
    }
  }
  .scale {
    transform: scale(calc((1920 - 800) / 1920));
    transform-origin: center right;
    @media screen and (max-width: 1200px){
      transform: none!important
      transform-origin: none!important
    }
  }
  &-tip {
    width 663px
    height 90px
    position fixed
    bottom 40px
    left 0
    z-index 5
    background url('https://qncweb.ktvsky.com/20240415/other/9182ca281f928e507297a8633b6a5f83.png') no-repeat
    background-size 100% 100%
    @media screen and (max-width: 1200px){
      bottom 940px
    }
    span {
      position absolute
      width 40px
      height 40px
      right 29px
      top 29px
    }
  }
  .loginTit{
    @media screen and (max-width: 1200px){
      margin-left 10px!important
    }
  }
  .exitTit{
    @media screen and (max-width: 1200px){
      margin-left 220px!important
    }
  }
}
</style>
  