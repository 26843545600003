<template>
  <div
    class="page aiface"
    v-log="{
      event_type: 'show',
      event_name: 30216,
      event_data: {
        str1: '换脸mv页',
        str2: '换脸mv页',
        str3: '展示',
        str4: 'show',
        str5: isLogin.value ? '已登录' : '未登录',
      }
    }"
    @click.stop="handleControlVideoPlayerTopMenu"
  >
    <VideoControlLayer
      v-show="isShowVideoPlayerPlugins"
    >
      <div class="aiface-controls-back" @click="handleClickBack"></div>
      <VideoControl
        :paused="!isPlaying"
        :shortMode="isShowMvSideBar"
        poster="https://qncweb.ktvsky.com/20211216/vadd/b1e6cb49a76d753245ec0a5dd71356dc.png"
        @switchAudioTrack="handleSwitchAudioTrack"
        @videoControlPlay="handleVideoControlPlay"
        @videoControlPause="handleVideoControlPause"
        @videoControlReplay="handleVideoControlReplay"
        @videoControlNext="handleVideoControlNext"
        @videoControlShowList="handleShowAIOrderList"
      ></VideoControl>
    </VideoControlLayer>
    <VideoPlayer
      poster="https://qncweb.ktvsky.com/20211216/vadd/b1e6cb49a76d753245ec0a5dd71356dc.png"
      :src="aiFaceSong.url"
      @onPlayingChange="onPlayingChange"
    ></VideoPlayer>
    <SongInfoBar :aiFaceSong="aiFaceSong" />
  </div>
</template>

<script>
import {
  ref,
  onMounted,
  watch,
  computed,
} from 'vue'
import { useStore } from 'vuex'
import { useRouter, useRoute } from 'vue-router';
import VideoPlayer from '@/components/video-player/mp4.vue'
import VideoControlLayer from '@/components/video-player/plugins/index.vue'
import VideoControl from './components/control.vue'
import SongInfoBar from './components/songinfo-bar/index.vue'
import eventBus from '@/utils/event-bus'
import useLoginValid from '@/composables/useLoginValid'
import { sendLog } from '@/directives/v-log/log'
import { get } from 'lodash'
import Toast from '@/utils/toast'

export default {
  name: 'aiface',
  components: {
    VideoPlayer,
    VideoControlLayer,
    VideoControl,
    SongInfoBar,
  },
  setup() {
    const store = useStore()
    const router = useRouter();
    const route = useRoute();
    const { isLogin } = useLoginValid()

    let isShowVideoPlayerPlugins = ref(true)
    let isShowVideoPlayerPluginsTimer = ref(0)
    let startPosition = ref(0)
    let initAutoPlay = ref(false)
    const isPlaying = ref(false)
    const aiFaceSong = computed(() => get(store.state.aiFaceSong, '[0]', {}))
    const mvIsHide = computed(() => store.state.mvIsHide)

    const onPlayingChange = (payload) => {
      isPlaying.value = payload
    }

    onMounted(() => {
      eventBus.emit('close-order-song-control-popup')
      eventBus.emit('handle-video-pause')
      if (!mvIsHide.value || route.query.from === 'climax') {
        Toast('MV自制完成，已自动切换')
      }
      store.commit('UPDATE_MV_ISHIDE', true)
    })

    // "点歌类型：1：点歌屏操作，2：按键操作，3：扫描点歌, 4: 首页会员优化(特斯拉)
    // TODO 临时先这么处理type 不太准确: 状态类型的上报比如 自然的播放结束 拿不到真实type
    const handleVideoControlPlay = async (type = 1) => {
      console.log('play')
      eventBus.emit('mp4-control-play')
    }

    const handleVideoControlPause = (type = 1) => {
      eventBus.emit('mp4-control-pause')
    }

    const handleVideoControlReplay = (type = 1) => {
      eventBus.emit('mp4-control-replay')
    }

    const handleVideoControlNext = () => {
      store.commit('SHIFT_AI_FACE')
      eventBus.emit('mp4-control-replay')
    }

    const handleShowAIOrderList = () => {
      eventBus.emit('show-ai-order-song-control-popup')
    }

    const handleControlVideoPlayerTopMenu = () => {
      sendLog({
        event_type: '10000~50000',
        event_name: isShowVideoPlayerPlugins.value ? 10048 : 10047,
        event_data: {
          str1: 'MV',
          str2: '画面区',
          str3: isShowVideoPlayerPlugins.value ? '收回播控' : '唤起播控',
          str4: 'click',
        },
      })
      isShowVideoPlayerPlugins.value = !isShowVideoPlayerPlugins.value
    }

    watch(isShowVideoPlayerPlugins, (val) => {
      // 开启状态
      if (val) {
        if (isShowVideoPlayerPluginsTimer.value) {
          clearTimeout(isShowVideoPlayerPluginsTimer.value)
        }
        isShowVideoPlayerPluginsTimer.value = setTimeout(() => {
          isShowVideoPlayerPlugins.value = false
        }, 6 * 1000)
      }
    })

    const handleClickBack = () => {
      router.go(-1);
    };

    return {
      isLogin,
      isShowVideoPlayerPluginsTimer,
      isShowVideoPlayerPlugins,
      initAutoPlay,
      startPosition,
      handleVideoControlPlay,
      handleVideoControlPause,
      handleVideoControlReplay,
      handleVideoControlNext,
      handleShowAIOrderList,
      handleControlVideoPlayerTopMenu,
      onPlayingChange,
      isPlaying,
      aiFaceSong,
      handleClickBack,
    }
  },
}
</script>

<style lang="stylus" scoped>
.page
  position relative
  z-index 100
  height 100vh
  display flex
  padding 0 !important
  .aiface-controls-back {
    width 130px
    height 130px
    background-color rgba(30, 31, 33, 0.7)
    background-image url('https://qncweb.ktvsky.com/20231207/vadd/72ff0b114ee2cb3153ce901af19bc813.png')
    background-repeat no-repeat
    background-size 40px 40px
    background-position center
    position fixed
    top 40px
    left 40px
    z-index 5
    border-radius: 10px;
  }
</style>

