<template>
  <div
    class="control-container"
    :class="{
      'short-mv-control-left': shortMode === 1,
      'short-mv-control-right': shortMode === 2,
    }"
    @click="$emit('video-control-close')"
  >
    <div class="control-container-top">
      <div
        class="control-container-item"
        @click.stop="$emit('video-control-replay')"
        v-log="{
          event_type: 'click',
          event_name: 30218,
          event_data: {
            str1: '换脸mv页',
            str2: '重播',
            str3: '点击',
            str4: 'click',
          }
        }"
      >
        <!-- 重唱 -->
        <img
          src="https://qncweb.ktvsky.com/20231214/other/1357d97c550111adc5a8bc4c0eaeb625.svg"
        />
        重唱
      </div>
      <div class="control-container-item" @click.stop="handleClickPlayPause">
        <!-- 播放 -->
        <img
          v-show="paused"
          src="https://qncweb.ktvsky.com/20231214/other/29460641498a84c23abc647d79958bef.svg"
        />
        <img
          v-show="!paused"
          src="https://qncweb.ktvsky.com/20231214/other/e81dfa7401137aa23a717f92b78e5bcd.svg"
          alt=""
        />
        {{ paused ? '播放' : '暂停' }}
      </div>
      <div
        class="control-container-item"
        @click.stop="$emit('video-control-next')"
        v-log="{
          event_type: 'click',
          event_name: 30220,
          event_data: {
            str1: '换脸mv页',
            str2: '切歌',
            str3: '点击',
            str4: 'click',
          }
        }"
      >
        <!-- 重唱 -->
        <img
          src="https://qncweb.ktvsky.com/20231214/other/f19b7818143607bd0bc178256bbc5cd9.svg"
        />
        切歌
      </div>
      <div
        class="control-container-item ordered"
        @click.stop="$emit('video-control-show-list')"
        v-log="{
          event_type: 'click',
          event_name: 30222,
          event_data: {
            str1: '换脸mv页',
            str2: '已点',
            str3: '点击',
            str4: 'click',
          }
        }"
      >
        <img
          src="https://qncweb.ktvsky.com/20231205/vadd/47998cdc7df85157af5fbe6b74b0728c.png"
        />
        已点
      </div>
    </div>
  </div>
</template>

<script>
import { computed, toRefs, ref, watch } from 'vue'
import useOrder from '@/composables/useOrder'
import { useRouter } from 'vue-router'
import { sendLog } from '@/directives/v-log/log'

export default {
  name: 'VideoControl',
  props: {
    paused: {
      type: Boolean,
      default: true,
    },
    shortMode: {
      // 适配mv页小屏幕播放模式：0：播控正常显示 1：播控左侧显示 2：播控右侧显示
      type: Number,
      default: 0,
    },
    audioTrackMap: {
      type: Object,
      default() {
        return {
          acc: {},
          org: {},
        }
      },
    },
    enabledAudioTrackId: {
      type: Number,
    },
  },
  setup(props, { emit }) {
    const router = useRouter()
    const { shortMode, paused } = toRefs(props)

    const size = computed(() => (shortMode.value > 0 ? 120 : 140))
    const { orderedList } = useOrder()
    const nextOrderSong = ref({})

    const iconConfig = {
      org: 'https://qncweb.ktvsky.com/20231214/other/2d680ecf3ee5faf2edd30c46c02d5ef1.svg',
      acc: 'https://qncweb.ktvsky.com/20231214/other/8686717d1d0f8e06c2d90668cef93b50.svg',
    }

    const handleClickPlayPause = () => {
      sendLog({
        event_type: 'click',
        event_name: 30219,
        event_data: {
          str1: '换脸mv页',
          str2: '暂停',
          str3: '点击',
          str4: 'click',
        }
      })
      if (paused.value) {
        emit('video-control-play')
      } else {
        emit('video-control-pause')
      }
    }

    const handleClick = () => {
      router.replace('/')
    }

    watch(orderedList.value, (val) => {
      nextOrderSong.value = val[1]
    })

    return {
      size,
      handleClickPlayPause,
      iconConfig,
      nextOrderSong,
      handleClick,
    }
  },
}
</script>

<style lang="stylus" scoped>
.control-container
  z-index 10
  width fit-content
  margin 0 auto
  &-top
    display flex
    justify-content center
    align-items center
    @media screen and (max-width 1200px) and (min-height 1200px)
      width 100vw
      position absolute
      top 900px
      left 50%
      transform translateX(-50%)
  &-item
    width auto
    height 120px
    padding 0 40px
    display flex
    justify-content center
    align-items center
    border-radius 100px
    background rgba(30, 31, 33, 0.70)
    backdrop-filter blur(15px)
    color rgba(255, 255, 255, 0.80)
    font-size 36px
    img
      margin-right 20px
      width 48px
    &:nth-child(2)
      margin 0 24px
    @media screen and (max-width 1200px) and (min-height 1200px)
      height 96px
      padding 0 33px
      border-radius 80px
      background rgba(255, 255, 255, 0.10)
      backdrop-filter blur(12px)
      font-size 28px
      img
        margin-right 16px
        width 40px
      &:nth-child(2)
        margin 0 22px
    &.ordered
      margin-left 24px
    &.next
      margin 40px 0 0
      justify-content flex-start
      img
        margin-right 40px
      .next-song
        flex 1
        max-width 540px
        padding 0 60px
        height 80px
        line-height 80px
        color: rgba(255, 255, 255, 0.60);
        text-align center
        white-space nowrap
        overflow hidden
        text-overflow ellipsis
        border-left 2px solid rgba(255, 255, 255, 0.20)
        @media screen and (max-width 1200px)
          max-width 450px
      .marquee-container
        width 450px
        overflow: hidden;
        margin 0 auto
        display flex
      .marquee-content
        white-space: nowrap;
        margin-right 10px
      @media screen and (max-width 1200px)
        margin-top 32px
        img
          margin-right 26px
</style>

